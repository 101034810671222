<template>
  <div class="homeBox">
    <a-layout>
      <a-anchor>
        <a-layout-header style="height: 150px; width: 100%; overflow: hidden">
          <div>
            <img
              src="http://tech001.oss-cn-hangzhou.aliyuncs.com/%E4%B8%AD%E5%BC%98%E6%B6%A6%E6%99%AF%E5%AE%98%E7%BD%91%E5%9B%BE%E7%89%87/logo.png"
              style="width: auto; height: 70%; display: block; margin: 10px"
              alt=""
            />
          </div>
          <a-menu
            v-model:selectedKeys="current"
            mode="horizontal"
            style="width: 60%"
          >
            <a-menu-item key="HomePage" @click="HomePageBtn">
              首页
            </a-menu-item>
            <a-menu-item key="secondItem" @click="secondItemBtn">
              愿景
            </a-menu-item>
            <a-menu-item key="thirdItem" @click="thirdItemBtn">
              产品
            </a-menu-item>
            <a-menu-item key="fourthItem" @click="fourthItemBtn">
              关于我们
            </a-menu-item>
          </a-menu>
        </a-layout-header>
      </a-anchor>
      <a-layout-content>
        <!-- <router-view></router-view> -->
        <div id="HomePage">
          <img
            src="http://tech001.oss-cn-hangzhou.aliyuncs.com/%E4%B8%AD%E5%BC%98%E6%B6%A6%E6%99%AF%E5%AE%98%E7%BD%91%E5%9B%BE%E7%89%87/%E5%BE%AE%E4%BF%A1%E5%9B%BE%E7%89%87_20230201121343.jpg"
            style="width: 100%; height: auto; display: block"
          />
        </div>
        <div
          style="
            width: 100%;
            height: 100px;
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 20px;
            font-weight: bold;
            color: #644ca4;
            margin-bottom: -30px;
          "
          id="secondItem"
        >
          <p>——————</p>
          <p style="width: 20px"></p>
          <p>公司愿景</p>
          <p style="width: 20px"></p>
          <p>——————</p>
        </div>
        <div
          style="
            width: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            padding-left: 10%;
          "
        >
          <div
            style="
              width: 500px;
              background: #644ca4;
              text-align: left;
              color: white;
              font-size: 14px;
              padding: 20px;
              border-top-left-radius: 10px;
              border-bottom-left-radius: 10px;
            "
          >
            <p>
              以健康管理及医疗服务为基础，提供保险、健康管理服务等综合解决方案，做客户的健康管家。
            </p>
            <p style="margin: 20px 0">
              以客户为中心，整合优质健康医疗资源，通过”服务+风控”，实现客户长期有效经营深度挖掘客户需求，打造可扩展的服务平台，提供主动式的解决方案，建立长期价值。
            </p>
            <p>
              丰富的服务产品库、保险与服务组合配置，实现诊前、诊中和诊后的闭环服务。
            </p>
          </div>
          <div style="width: 50%; height: 350px">
            <img
              src="http://tech001.oss-cn-hangzhou.aliyuncs.com/%E4%B8%AD%E5%BC%98%E6%B6%A6%E6%99%AF%E5%AE%98%E7%BD%91%E5%9B%BE%E7%89%87/%E5%9B%BE%E7%89%87.png"
              style="width: auto; height: 100%; display: block"
              alt=""
            />
          </div>
        </div>
        <div
          style="
            width: 100%;
            height: 100px;
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 20px;
            font-weight: bold;
            color: #644ca4;
            margin-bottom: -30px;
          "
          id="thirdItem"
        >
          <p>——————</p>
          <p style="width: 20px"></p>
          <p>产品</p>
          <p style="width: 20px"></p>
          <p>——————</p>
        </div>
        <div>
          <div
            style="
              display: flex;
              justify-content: space-between;
              padding: 0 18%;
              width: 100%;
              height: 400px;
            "
          >
            <div
              style="
                display: flex;
                text-align: left;
                width: 300px;
                margin-top: 70px;
              "
            >
              <div>
                <img
                  src="http://tech001.oss-cn-hangzhou.aliyuncs.com/%E4%B8%AD%E5%BC%98%E6%B6%A6%E6%99%AF%E5%AE%98%E7%BD%91%E5%9B%BE%E7%89%87/%E5%9B%BE%E5%B1%82%204%402x.png"
                  style="
                    width: 20px;
                    height: 18px;
                    margin-top: 7px;
                    margin-right: 5px;
                  "
                  alt=""
                />
              </div>
              <div>
                <h2>体检服务</h2>
                <p>入职基础体检</p>
                <p>企业年度团检</p>
                <p>专项体检</p>
              </div>
            </div>
            <div style="">
              <img
                src="http://tech001.oss-cn-hangzhou.aliyuncs.com/%E4%B8%AD%E5%BC%98%E6%B6%A6%E6%99%AF%E5%AE%98%E7%BD%91%E5%9B%BE%E7%89%87/%E6%8F%92%E7%94%BB2.png"
                style="width: auto; height: 110%; display: block"
                alt=""
              />
            </div>
          </div>
          <div
            style="
              display: flex;
              justify-content: space-between;
              padding: 0 18%;
              width: 100%;
              height: 400px;
            "
          >
            <div style="">
              <img
                src="http://tech001.oss-cn-hangzhou.aliyuncs.com/%E4%B8%AD%E5%BC%98%E6%B6%A6%E6%99%AF%E5%AE%98%E7%BD%91%E5%9B%BE%E7%89%87/%E7%BB%84%2010%402x.png"
                style="width: auto; height: 70%; display: block"
                alt=""
              />
            </div>
            <div
              style="
                display: flex;
                text-align: left;
                width: 300px;
                margin-top: 70px;
              "
            >
              <div>
                <img
                  src="http://tech001.oss-cn-hangzhou.aliyuncs.com/%E4%B8%AD%E5%BC%98%E6%B6%A6%E6%99%AF%E5%AE%98%E7%BD%91%E5%9B%BE%E7%89%87/%E6%89%80%E7%94%A8%E5%9C%BA%E6%99%AF%402x.png"
                  style="
                    width: 20px;
                    height: 20px;
                    margin-top: 7px;
                    margin-right: 5px;
                  "
                  alt=""
                />
              </div>
              <div>
                <h2>以患者为中心的场景化服务</h2>
                <p>对于新确诊、复发及进展患者进行精确转诊</p>
                <p>对于术后、院外康复期患者进行随访管理</p>
                <p>对于癌症新用药患者进行副反应管理</p>
              </div>
            </div>
          </div>
          <div
            style="
              display: flex;
              justify-content: space-between;
              padding: 0 18%;
              width: 100%;
              height: 400px;
            "
          >
            <div
              style="
                display: flex;
                text-align: left;
                width: 300px;
                margin-top: 70px;
              "
            >
              <div>
                <img
                  src="http://tech001.oss-cn-hangzhou.aliyuncs.com/%E4%B8%AD%E5%BC%98%E6%B6%A6%E6%99%AF%E5%AE%98%E7%BD%91%E5%9B%BE%E7%89%87/icon%204.png"
                  style="
                    width: 17px;
                    height: 20px;
                    margin-top: 7px;
                    margin-right: 5px;
                  "
                  alt=""
                />
              </div>
              <div>
                <h2>预防及慢病健康管理</h2>
                <p>个人健康档案</p>
                <p>预防管理方案</p>
                <p>慢病管理</p>
              </div>
            </div>
            <div style="">
              <img
                src="http://tech001.oss-cn-hangzhou.aliyuncs.com/%E4%B8%AD%E5%BC%98%E6%B6%A6%E6%99%AF%E5%AE%98%E7%BD%91%E5%9B%BE%E7%89%87/%E6%8F%92%E7%94%BB3.png"
                style="width: auto; height: 100%; display: block"
                alt=""
              />
            </div>
          </div>
          <div
            style="
              display: flex;
              justify-content: space-between;
              padding: 0 18%;
              width: 100%;
              height: 400px;
            "
          >
            <div style="">
              <img
                src="http://tech001.oss-cn-hangzhou.aliyuncs.com/%E4%B8%AD%E5%BC%98%E6%B6%A6%E6%99%AF%E5%AE%98%E7%BD%91%E5%9B%BE%E7%89%87/%E6%8F%92%E7%94%BB4.png"
                style="width: auto; height: 80%; display: block"
                alt=""
              />
            </div>
            <div
              style="
                display: flex;
                text-align: left;
                width: 270px;
                margin-top: 50px;
              "
            >
              <div>
                <img
                  src="http://tech001.oss-cn-hangzhou.aliyuncs.com/%E4%B8%AD%E5%BC%98%E6%B6%A6%E6%99%AF%E5%AE%98%E7%BD%91%E5%9B%BE%E7%89%87/%E5%81%A5%E5%BA%B7%E7%AE%A1%E7%90%86%402x.png"
                  style="
                    width: 20px;
                    height: 20px;
                    margin-top: 5px;
                    margin-right: 5px;
                  "
                  alt=""
                />
              </div>
              <div>
                <h2>健管服务</h2>
                <p>陪诊</p>
                <p>绿通</p>
                <p>线上问诊</p>
                <p>二次诊疗</p>
                <p>数字医疗</p>
              </div>
            </div>
          </div>
        </div>
      </a-layout-content>
      <a-layout-footer style="padding: 20px 200px" id="fourthItem">
        <div
          style="
            display: flex;
            align-items: center;
            justify-content: space-between;
          "
        >
          <div style="text-align: left">
            <p>
              <span style="margin-right: 15px">
                <img
                  src="http://tech001.oss-cn-hangzhou.aliyuncs.com/%E4%B8%AD%E5%BC%98%E6%B6%A6%E6%99%AF%E5%AE%98%E7%BD%91%E5%9B%BE%E7%89%87/%E9%82%AE%E7%AE%B1%402x.png"
                  style="width: 15px; height: 15px"
                  alt=""
                />
              </span>
              邮箱：Service@Inf-Health.com
            </p>
            <p style="margin: 20px 0">
              <span style="margin-right: 15px">
                <img
                  src="http://tech001.oss-cn-hangzhou.aliyuncs.com/%E4%B8%AD%E5%BC%98%E6%B6%A6%E6%99%AF%E5%AE%98%E7%BD%91%E5%9B%BE%E7%89%87/%E5%9C%B0%E5%9D%80%402x.png"
                  style="width: 15px; height: 20px"
                  alt=""
                />
              </span>
              地址：上海市徐汇区宜山路888号2203室
            </p>
            <p>
              <span style="margin-right: 15px">
                <img
                  src="http://tech001.oss-cn-hangzhou.aliyuncs.com/%E4%B8%AD%E5%BC%98%E6%B6%A6%E6%99%AF%E5%AE%98%E7%BD%91%E5%9B%BE%E7%89%87/%E5%B7%A5%E4%BD%9C%E6%97%B6%E9%97%B4%402x.png"
                  style="width: 15px; height: 15px"
                  alt=""
                />
              </span>
              工作时间：周一至周五9:00-18:00
            </p>
          </div>
          <div>
            <p>
              <img
                src="http://tech001.oss-cn-hangzhou.aliyuncs.com/%E4%B8%AD%E5%BC%98%E6%B6%A6%E6%99%AF%E5%AE%98%E7%BD%91%E5%9B%BE%E7%89%87/%E5%85%AC%E4%BC%97%E5%8F%B7.jpg"
                style="width: 110px; height: 110px"
              />
            </p>
            <p>关注公众号</p>
          </div>
        </div>
        <div style="font-weight: bold; margin-left: 25px">
          <img src="https://tech001.oss-cn-hangzhou.aliyuncs.com/%E5%85%AC%E5%AE%89%E5%A4%87%E6%A1%88.png" style="width: 1.5%; height: 1.5%">
          <a
            href="http://beian.miit.gov.cn/"
            target="_blank"
            style="color: white"
            >沪公网安备31010402334052号</a
          ><br/>
          <a
            href="http://beian.miit.gov.cn/"
            target="_blank"
            style="color: white"
            >沪ICP备2023002880号</a
          >
          © 中弘润景健康 INF-Health 2022
        </div>
      </a-layout-footer>
    </a-layout>
    <a-back-top />
    <strong style="color: rgba(64, 64, 64, 0.6)"></strong>
  </div>
</template>

<script>
export default {
  name: "HomeView",
  components: {},
  data() {
    return {
      current: ["HomePage"],
    };
  },
  created() {
    this.current = [this.$route.name];
  },
  methods: {
    HomePageBtn() {
      document.getElementById("HomePage").scrollIntoView({
        behavior: "smooth",
        inline: "center",
        block: "center",
      });
    },
    secondItemBtn() {
      document.getElementById("secondItem").scrollIntoView({
        behavior: "smooth",
        inline: "center",
        block: "start",
      });
    },
    thirdItemBtn() {
      document.getElementById("thirdItem").scrollIntoView({
        behavior: "smooth",
        inline: "center",
        block: "start",
      });
    },
    fourthItemBtn() {
      document.getElementById("fourthItem").scrollIntoView({
        behavior: "smooth",
        inline: "center",
        block: "start",
      });
    },
  },
};
</script>

<style lang="less">
.ant-layout-header {
  height: 100px !important;
  background: white !important;
  display: flex !important;
  justify-content: space-between !important;
  align-items: center !important;
  border-bottom: 1px #ccc solid;
  div {
    height: 70px;
    img {
      width: 100%;
      height: 100%;
      display: block;
    }
  }
}
.ant-layout-content {
  flex: auto;
  min-height: 0;
  text-align: center;
  background: white;
  padding: 0px 80px !important;
}
.ant-layout-footer {
  text-align: center;
  padding: 24px 50px;
  color: white !important;
  font-size: 14px;
  background: #644ca4 !important;
}
.ant-menu-horizontal {
  line-height: 46px;
  border: 0;
  border-bottom: 1px solid #f0f0f0;
  box-shadow: none;
  justify-content: center !important;
}
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item {
  margin-top: -1px;
  margin-bottom: 0;
  padding: 0 60px !important;
  margin: 0 30px !important;
}
.ant-menu-horizontal > .ant-menu-item-selected a {
  font-weight: bold !important;
  color: #644ca4 !important;
}
.ant-menu-horizontal > .ant-menu-item a:hover {
  color: #644ca4 !important;
  font-weight: bold !important;
}
.van-tabs__nav--line {
  padding: 0 20% !important;
  padding-bottom: 15px !important;
  margin-bottom: 10px;
}
.van-tabs--line .van-tabs__wrap {
  margin-bottom: 5px !important;
}
#components-back-top-demo-custom .ant-back-top {
  bottom: 100px;
}
#components-back-top-demo-custom .ant-back-top-inner {
  border: 2px solid #fff;
  height: 40px;
  width: 40px;
  line-height: 40px;
  border-radius: 4px;
  background-color: #644ca4;
  color: #fff;
  text-align: center;
  border-radius: 50% !important;
  font-size: 18px;
}
</style>
